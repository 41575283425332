<template>
  <div class="banner-text">
    <div v-show="showBanner" class="top-banner">
      <el-button
        type="text"
        class="el-icon-close banner-close"
        @click="closeBanner"
      ></el-button>
      <img src="/img/banner.png" class="banner" alt="" />
    </div>
    <div class="banner-text-container">
      <div
        v-for="(item, index) in allSystem"
        :key="index"
        class="manu-item"
        @click="handleApp(item)"
      >
        <div class="manu-item-container">
          <div class="manu-item-img-background">
            <img
              :src="item.appIcon.substring(item.appIcon.indexOf('_:/') + 3)"
              class="manu-item-img"
              alt=""
            />
          </div>

          <div class="manu-item-synopsis">
            <div class="manu-item-label">
              <span style="border-bottom: 1px solid #e0e8f7">{{
                item.name
              }}</span>
            </div>
            <el-tooltip
              class="item"
              effect="light"
              :content="item.descript"
              placement="top"
            >
              <span class="manu-item-describe myNote">{{ item.descript }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAllSystem } from '@/api/admin/menu'
// import draggable from 'vuedraggable'
import Cookie from 'js-cookie'

export default {
  name: 'Wel',
  // components: {
  //   draggable,
  // },
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      DATA: [],
      text: '',
      actor: '',
      count: 0,
      isText: false,
      allSystem: [],
    }
  },
  computed: {
    ...mapGetters(['website', 'userInfo', 'showBanner']),
  },
  created() {
    this.showAppCenter()
  },
  methods: {
    closeBanner() {
      this.$store.commit('SET_SHOWBANNER')
    },
    // 展示应用中心
    showAppCenter() {
      getAllSystem(this.userInfo.username).then(res => {
        this.allSystem = res.data.data.filter(app => {
          return app.appCode !== 'paas'
        })
      })
    },
    handleApp(app) {
      if (app.isPlatFormFlag === '1') {
        if (app.appPath) {
          const token = JSON.parse(Cookie.get('OO-access_token')).content
          app.appPath = app.appPath.replace(
            /\$\{domain\}/,
            window.location.host,
          )
          app.appPath = app.appPath.replace(/\$\{token\}/, token)
          if (app.appPath.indexOf('http') !== -1) {
            window.open(app.appPath, '_blank')
          } else {
            window.open(`${window.location.protocol}//${app.appPath}`, '_blank')
          }
        } else {
          const origin = window.location.host.split('.')
          origin.splice(0, 1)
          const topOrigin = origin.join('.')
          window.open(
            `${window.location.protocol}//${app.appCode}.${topOrigin}`,
            '_blank',
          )
        }
      } else {
        window.open(`${window.location.origin}/app/${app.appCode}/`, '_blank')
      }
    },
  },
}
</script>

<style scoped="scoped" lang="scss">
.wel-contailer {
  position: relative;
}

.avue-view {
  height: calc(100% - 33px);
}

.banner-text {
  font-size: 20px;
  color: #333;
  margin: 0 auto;
  .top-banner {
    width: 100%;
    padding: 16px;
    padding-bottom: 0;
    position: relative;
    .banner-close {
      position: absolute;
      top: 10px;
      right: 25px;
      color: #1a1a1a;
      font-size: 14px;
    }
    .banner {
      width: 100%;
    }
  }
  .banner-text-container {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    .manu-item {
      width: 25%;
      min-width: 276px;
      height: 207px;
      padding: 8px;
      display: inline-block;
      cursor: pointer;

      .manu-item-container {
        background: #fff;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 15%;
        .manu-item-img-background {
          width: 100px;
          height: 100px;
          background-color: #f2fdff;
          line-height: 170px;
          text-align: center;
          box-shadow: 0px 4px 4px #6fc3ff4a;
          border-radius: 50px;
          .manu-item-img {
            width: 80px;
            height: 80px;
          }
        }

        .manu-item-synopsis {
          display: inline-block;
          margin-left: 16px;
          width: 64%;
          .manu-item-label {
            margin-top: 8px;
            font-size: 18px;
            display: block;
            // border-bottom: 1px solid #e0e8f7;
          }
          .manu-item-describe {
            margin-top: 8px;
            font-size: 16px;
            display: block;
            color: #d1d1d1;
          }
          .myNote {
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }
}
.banner-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  display: none;
}

.actor {
  height: 250px;
  overflow: hidden;
  font-size: 18px;
  color: #333;
}

.actor:after {
  content: '';
  width: 3px;
  height: 25px;
  vertical-align: -5px;
  margin-left: 5px;
  background-color: #333;
  display: inline-block;
  animation: blink 0.4s infinite alternate;
}

.typeing:after {
  animation: none;
}

@keyframes blink {
  to {
    opacity: 0;
  }
}
</style>
